import React, { Component } from "react";
import { Layout, Col, Row, Button, Divider } from "antd";
import {
  AndroidOutlined,
  AppleOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Base64Encoder } from "../../helper/Base64Encoder";
import { isIOS, isAndroid, isBrowser, browserName } from "react-device-detect";
import { generateLoginLink } from "../../firebase";
import { getFirebaseCustom } from "../../api/account";
import { getCustomToken } from "../../helper/goWebtrade";
const TITLE = "Trade Program | IUX";
const locale = require("react-redux-i18n").I18n;

function TradeProgramIUX() {
  const profile = useSelector((state) => state.user.profile);

  return (
    <Layout style={{ position: "relative" }}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="trading-program-content" style={{ borderRadius: 20 }}>
        {" "}
        <p className="text-header-account-wallet mt-4 mt-md-0">
          {locale.t("program")}
        </p>
        <div>
          <Divider />
          <div>
            <Row className="mt-2" gutter={16}>
              <Col xs={24} md={12} className="padding-trade-program  mb-2">
                <div className="">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        width={"100%"}
                        height={"100%"}
                        style={{ maxHeight: 320 }}
                        className="platform-border-col"
                        // className="platform-img-metatrader"
                        alt="webtrade"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/webtrade.png"
                        }
                      />
                    </div>
                    <div className="mt-4  d-flex justify-content-center">
                      {/* <a
                        href={`${
                          process.env.REACT_APP_DOMAIN_TRADING
                        }login/${Base64Encoder(
                          localStorage.getItem("access_token")
                        )}`}
                        rel="noreferrer"
                        target={"_blank"}
                        className="text-primary-new-iux cursor-pointer"
                      > */}
                      <Button
                        className=" btn-primary-new-iux   "
                        onClick={() => getCustomToken()}
                      >
                        IUX Web Trade
                      </Button>
                      {/* </a> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} className="padding-trade-program  mb-2">
                <div className="">
                  <div className="pb-4">
                    <div className="text-center">
                      <img
                        width={"100%"}
                        height={"100%"}
                        className="platform-border-col"
                        alt="mobile"
                        style={{ maxHeight: 320 }}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/trading-sofware/mobiletrade.png"
                        }
                      />
                    </div>
                    <div className="mt-4 d-flex justify-content-center">
                      <a
                        href={
                          isIOS || isBrowser || isAndroid
                            ? "https://apps.apple.com/th/app/iux-trader/id6444305135"
                            : "https://play.google.com/store/apps/details?id=com.iuxmarket.app"
                        }
                        target={"_blank"}
                        rel="noreferrer"
                        className="text-primary-new-iux cursor-pointer"
                      >
                        <Button className=" btn-primary-new-iux ">
                          IUX App Trade
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Divider />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TradeProgramIUX;
