import { Col, Row } from "antd";
import React, { useEffect } from "react";
import RcQueueAnim from "rc-queue-anim";
import { Link, Route, Switch } from "react-router-dom";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import AccountOverview from "./account-overview";
import Accountstandard from "./account-standard";
import AccountBonus from "./account-bonus";
import AccountEcn from "./account-ecn";
import AccountRaw from "./account-raw";
import AccountFunding from "./account-funding";
import AccountWithdraw from "./account-withdraw";
import Accountcent from "./account-cent";
import { useDispatch, useSelector } from "react-redux";
import { getAccountTypeList } from "../../actions";
import AccountPro from "./account-pro";

const TITLE = "Account Overview | IUX";
const locale = require("react-redux-i18n").I18n;

const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
  font-weight: ${(props) => props.fontWeight};
`;

const textStyle = {
  color: "white",
};

const TextWithCover = (props) => {
  return (
    <div
      className="cover-responsive"
      style={{
        backgroundImage: `url("${props.imagePath}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="h-100"
        style={{
          background:
            "linear-gradient(113deg, rgba(0,0,0,1) 60%, rgba(0,0,0,0.5) 100%)",
        }}
      >
        <Row className="d-flex container h-100">
          <Col className="" span={24} style={{ alignSelf: "center" }}>
            <h2 className="" style={textStyle}>
              {props.title}
            </h2>
            <p
              className="text-leftresponsive-content mt-4 fs-20"
              style={textStyle}
            >
              {props.content}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const AccountIndex = (props) => {
  const accountList = useSelector((state) => state.account);
  const pathName = window.location.pathname;
  const [isIdex, setIndex] = React.useState(5);
  const menu = [
    {
      id: 0,
      link: `/${localStorage.getItem("locale")}/accounts`,
      text: locale.t("cover.accover.title"),
    },
    // {
    //   id: 1,
    //   link: `/${localStorage.getItem("locale")}/accounts/standard`,
    //   text: locale.t("cover.accstd.title"),
    // },
    // {
    //   id: 2,
    //   link: `/${localStorage.getItem("locale")}/accounts/standard+`,
    //   text: locale.t("cover.accbonus.title"),
    // },
    // {
    //   id: 3,
    //   link: `/${localStorage.getItem("locale")}/accounts/ecn`,
    //   text: locale.t("cover.accecn.title"),
    // },
    // {
    //   id: 3,
    //   link: `/${localStorage.getItem("locale")}/accounts/raw`,
    //   text: locale.t("cover.accraw.title"),
    // },
    // {
    //   id: 4,
    //   link: `/${localStorage.getItem("locale")}/accounts/pro`,
    //   text: locale.t("cover.accpro.title"),
    // },
    // {
    //   id: 5,
    //   link: `/${localStorage.getItem("locale")}/accounts/cent`,
    //   text: locale.t("cover.acccnt.title"),
    // },
    {
      id: 5,
      link: `/${localStorage.getItem("locale")}/accounts/funding`,
      text: locale.t("cover.deposit.title"),
    },
    {
      id: 6,
      link: `/${localStorage.getItem("locale")}/accounts/withdrawal`,
      text: locale.t("cover.withdraw.title"),
    },
  ];
  const imgcover = [
    {
      display: `/${localStorage.getItem("locale")}/accounts`,
      title: locale.t("cover.accover.title"),
      description: locale.t("cover.accover.description"),
      image: "/assets/images/covers/overview.png",
    },
    {
      display: `/${localStorage.getItem("locale")}/accounts/Standard`,
      title: locale.t("cover.accstd.title"),
      description: locale.t("cover.accstd.description"),
      image: "/assets/images/covers/overview.png",
    },
    {
      display: `/${localStorage.getItem("locale")}/accounts/Standard+`,
      title: locale.t("cover.accbonus.title"),
      description: locale.t("cover.accbonus.description"),
      image: "/assets/images/covers/overview.png",
    },
    // {
    //   title: locale.t("cover.accecn.title"),
    //   description: locale.t("cover.accecn.description"),
    //   image: "/assets/images/covers/overview.png",
    // },
    {
      display: `/${localStorage.getItem("locale")}/accounts/Raw`,
      title: locale.t("cover.accraw.title"),
      description: locale.t("cover.accraw.description"),
      image: "/assets/images/covers/overview.png",
    },
    {
      display: `/${localStorage.getItem("locale")}/accounts/Pro`,
      title: locale.t("cover.accpro.title"),
      description: locale.t("cover.accpro.description"),
      image: "/assets/images/covers/overview.png",
    },
    // {
    //   title: locale.t("cover.acccnt.title"),
    //   description: locale.t("cover.acccnt.description"),
    //   image: "/assets/images/covers/overview.png",
    // },
    {
      display: `/${localStorage.getItem("locale")}/accounts/funding`,
      title: locale.t("cover.deposit.title"),
      description: locale.t("cover.deposit.description"),
      image: "/assets/images/covers/fund-cover.png",
    },
    {
      display: `/${localStorage.getItem("locale")}/accounts/withdrawal`,
      title: locale.t("cover.withdraw.title"),
      description: locale.t("cover.withdraw.description"),
      image: "/assets/images/covers/fund-cover.png",
    },
  ];

  return (
    <RcQueueAnim className="bg-black">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <TextWithCover
        key={1}
        imagePath={
          process.env.PUBLIC_URL +
          imgcover[imgcover?.findIndex((e) => e.display === pathName)]?.image
        }
        title={
          imgcover[imgcover?.findIndex((e) => e.display === pathName)]?.title
        }
        content={
          <>
            <div>
              <p className="text-left">
                {
                  imgcover[imgcover.findIndex((e) => e.display === pathName)]
                    ?.description
                }
              </p>
              {/* {renderButtonCover()} */}
            </div>
          </>
        }
      />
      <div className="sub-menu-page">
        <div className="container">
          <div className="d-flex overflow-auto scroll-menu-hide">
            <Link to={`/${localStorage.getItem("locale")}/accounts`}>
              <MenuItem
                onClick={() => setIndex(0)}
                color={
                  `/${localStorage.getItem("locale")}/accounts` === pathName
                    ? "#FFF"
                    : "#8D8D8D"
                }
                borderBottom={
                  `/${localStorage.getItem("locale")}/accounts` === pathName
                    ? "4px solid #1CB894"
                    : "2px solid #ff000000"
                }
                fontWeight={
                  `/${localStorage.getItem("locale")}/accounts` === pathName
                    ? 400
                    : "unset"
                }
              >
                {locale.t("cover.accover.title")}
              </MenuItem>
            </Link>

            {accountList.accountTypeList
              ?.filter((e) => e.active === 1 && e.type === "real")
              ?.map((e, i) => (
                <Link
                  to={`/${localStorage.getItem("locale")}/accounts/${
                    e.display
                  }`}
                >
                  <MenuItem
                    onClick={() => setIndex(i)}
                    color={
                      `/${localStorage.getItem("locale")}/accounts/${
                        e.display
                      }` === pathName
                        ? "#FFF"
                        : "#8D8D8D"
                    }
                    borderBottom={
                      `/${localStorage.getItem("locale")}/accounts/${
                        e.display
                      }` === pathName
                        ? "4px solid #1CB894"
                        : "2px solid #ff000000"
                    }
                    fontWeight={
                      `/${localStorage.getItem("locale")}/accounts/${
                        e.display
                      }` === pathName
                        ? 400
                        : "unset"
                    }
                  >
                    {e.display}
                  </MenuItem>
                </Link>
              ))}

            <Link to={`/${localStorage.getItem("locale")}/accounts/funding`}>
              <MenuItem
                onClick={() => setIndex(0)}
                color={
                  `/${localStorage.getItem("locale")}/accounts/funding` ===
                  pathName
                    ? "#FFF"
                    : "#8D8D8D"
                }
                borderBottom={
                  `/${localStorage.getItem("locale")}/accounts/funding` ===
                  pathName
                    ? "4px solid #1CB894"
                    : "2px solid #ff000000"
                }
                fontWeight={
                  `/${localStorage.getItem("locale")}/accounts/funding` ===
                  pathName
                    ? 400
                    : "unset"
                }
              >
                {locale.t("cover.deposit.title")}
              </MenuItem>
            </Link>
            <Link to={`/${localStorage.getItem("locale")}/accounts/withdrawal`}>
              <MenuItem
                onClick={() => setIndex(0)}
                color={
                  `/${localStorage.getItem("locale")}/accounts/withdrawal` ===
                  pathName
                    ? "#FFF"
                    : "#8D8D8D"
                }
                borderBottom={
                  `/${localStorage.getItem("locale")}/accounts/withdrawal` ===
                  pathName
                    ? "4px solid #1CB894"
                    : "2px solid #ff000000"
                }
                fontWeight={
                  `/${localStorage.getItem("locale")}/accounts/withdrawal` ===
                  pathName
                    ? 400
                    : "unset"
                }
              >
                {locale.t("cover.withdraw.title")}
              </MenuItem>
            </Link>
          </div>
        </div>
      </div>
      <Switch>
        <Route
          path={`/:lang/accounts/withdrawal`}
          render={() => {
            return <AccountWithdraw />;
          }}
        />
        <Route
          path={`/:lang/accounts/funding`}
          render={() => {
            return <AccountFunding />;
          }}
        />
        {/* <Route
          path={`/:lang/accounts/ecn`}
          render={() => {
            return <AccountEcn />;
          }}
        /> */}
        <Route
          path={`/:lang/accounts/raw`}
          render={() => {
            return <AccountRaw />;
          }}
        />
        <Route
          path={`/:lang/accounts/pro`}
          render={() => {
            return <AccountPro />;
          }}
        />

        <Route
          path={`/:lang/accounts/standard+`}
          render={() => {
            return <AccountBonus />;
          }}
        />
        <Route
          path={`/:lang/accounts/standard`}
          render={() => {
            return <Accountstandard />;
          }}
        />
        {/* <Route
          path={`/:lang/accounts/cent`}
          render={() => {
            return <Accountcent />;
          }}
        /> */}
        <Route
          path={`/:lang/accounts`}
          render={() => {
            return <AccountOverview />;
          }}
        />
      </Switch>
      <section key={10}>
        <FooterLandingPage locale={locale} />
      </section>
    </RcQueueAnim>
  );
};

export default AccountIndex;
