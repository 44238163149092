import { Button, Modal, Collapse, Divider } from "antd";
import React, { Component } from "react";
import IconPanelPartner from "../../components/icon-panel/IconPanelPartner";
import TextWithCoverPartner from "../../components/text-with-cover/TextWithCoverPartner";
import RcQueueAnim from "rc-queue-anim";
import { connect } from "react-redux";

import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Link, Route, Switch } from "react-router-dom";
import Landingpartner from "./landingpartner";
import Guildline from "./Guildline";
const Card = styled.div`
  padding: 16px;
  border-radius: 16px;
  height: 140px;
  width: 315px;
`;

const TITLE = "Partner | IUX";

const locale = require("react-redux-i18n").I18n;
const { Panel } = Collapse;
const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
  font-weight: ${(props) => props.fontWeight};
`;

const PartnerPage = () => {
  const pathName = window.location.pathname;
  const [isIdex, setIndex] = React.useState(0);
  const menu = [
    {
      id: 0,
      link: `/${localStorage.getItem("locale")}/partner`,
      text: "About us",
    },
    {
      id: 1,
      link: `/${localStorage.getItem("locale")}/partner/guildline`,
      text: "Marketing guideline",
    },
    {
      id: 2,
      link: `/${localStorage.getItem("locale")}/about-us/contact`,
      text: "Contact Us",
    },
  ];
  const imgcover = [
    {
      title: "Partner Program",
      subtitle: "UP TO 40% OF SPREAD COMMISSIONS",
      description: locale.t("partner_header.description"),
      image:
        process.env.PUBLIC_URL +
        "/assets/images/covers/covernew-partnerrship.png",
    },
    {
      title: locale.t("guildeline_banner"),
      subtitle: "",
      description: locale.t("guildeline_sub_banner"),
      image: "",
    },
    {
      title: "Contact Us",
      subtitle: "",
      description: "",
      image: "",
    },
  ];
  const alertContactPartner = () => {
    Modal.info({
      title: "iUXmarkets",
      content: (
        <div>
          <p>เข้าร่วมเป็นพันธมิตร จะต้องติดต่อโดยตรงเท่านั้น</p>
          <p>ติดต่อ : support@iuxmarkets.com</p>
        </div>
      ),
      onOk() {},
    });
  };
  React.useEffect(() => {
    const activeCover = () => {
      if (pathName) {
        menu.filter((e, i) => e.link === pathName && setIndex(e.id));
      }
    };
    activeCover();
  }, [pathName]);
  return (
    <RcQueueAnim className="bg-black">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <section key={1} id="banner" style={{ width: "100%" }}>
        <TextWithCoverPartner
          key={2}
          styles={{ backgroundPosition: "center !important" }}
          imagePath={imgcover[isIdex].image}
          subtitle={imgcover[isIdex].subtitle}
          title={imgcover[isIdex].title}
          content={
            <>
              {imgcover[isIdex].description}
              <Link to={`/${localStorage.getItem("locale")}/formpartner`}>
                <div className="d-flex justify-content-center">
                  <div className="">
                    <Button className="mt-4 col-md-12 mt-2 btn-yellow-new-iux">
                      {locale.t("partner_header.button")}
                    </Button>
                  </div>
                </div>
              </Link>
            </>
          }
        />
      </section>{" "}
      <div className="sub-menu-page">
        <div className="container">
          <div className="d-flex overflow-auto scroll-menu-hide">
            {menu.map((e, i) => (
              <Link to={e.link}>
                <MenuItem
                  onClick={() => setIndex(i)}
                  color={e.link === pathName ? "#FFF" : "#8D8D8D"}
                  borderBottom={
                    e.link === pathName
                      ? "4px solid #DCBE59"
                      : "2px solid #ff000000"
                  }
                  fontWeight={e.link === pathName ? 400 : "unset"}
                >
                  {e.text}
                </MenuItem>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Switch>
        <Route
          path={`/:lang/partner/guildline`}
          render={() => {
            return <Guildline />;
          }}
        />{" "}
        <Route
          path={`/:lang/partner`}
          render={() => {
            return <Landingpartner />;
          }}
        />
        {/* <Route
            path={`/:lang/accounts/funding`}
            render={() => {
              return <AccountFunding />;
            }}
          />
          <Route
            path={`/:lang/accounts/ecn`}
            render={() => {
              return <AccountEcn />;
            }}
          />
          <Route
            path={`/:lang/accounts/bonus`}
            render={() => {
              return <AccountBonus />;
            }}
          />
          <Route
            path={`/:lang/accounts/standard`}
            render={() => {
              return <Accountstandard />;
            }}
          />
          <Route
            path={`/:lang/accounts/cent`}
            render={() => {
              return <Accountcent />;
            }}
          />
          <Route
            path={`/:lang/accounts`}
            render={() => {
              return <AccountOverview />;
            }}
          /> */}
      </Switch>
      <section key={10}>
        <FooterLandingPage locale={locale} />
      </section>
    </RcQueueAnim>
  );
};

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(
  mapStateToProps,

  null
)(PartnerPage);
