import React, { Component } from "react";
import { StyledContent } from "../../components/content/Content";
import { Layout } from "antd";
import { Tabs } from "antd";
// import Invite from './friend/Invite';
import Commission from "./friend/Commission";
// import Customer from './friend/Customer';
import { Helmet } from "react-helmet";
import { onOpenPolicy } from "../../helper/policy";
const TITLE = "Lot Back | IUX";
const { TabPane } = Tabs;
const locale = require("react-redux-i18n").I18n;
class Lotback extends Component {
  render() {
    return (
      <Layout
        style={{
          padding: "24px 0px",
          position: "relative",
          maxWidth: "1160px",
        }}
      >
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="bg-white p-4" style={{ borderRadius: 20 }}>
          <div className="d-flex justify-content-between align-items-end">
            <p className="text-header-account-wallet text-left w-100">
              LotBack
            </p>
            <div
              className="text-primary-new-iux cursor-pointer w-100 text-right"
              onClick={() => onOpenPolicy("term_iux_market_lotback")}
            >
              {locale.t("contact_sidebar.lable_4")} LotBack
            </div>
          </div>
          <Commission />
        </div>
      </Layout>
    );
  }
}

export default Lotback;
