import React, { Component } from "react";
import { Row, Button } from "antd";
import { Link } from "react-router-dom";
import QueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import SectionOne from "../component/SectionOne";
import SectionTwo from "../component/SectionTwo";
import SectionThree from "../component/SectionThree";
const TITLE = "Indices - Market | IUX";
const locale = require("react-redux-i18n").I18n;

export class Indicesforex extends Component {
  render() {
    return (
      <QueueAnim>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>{" "}
        <SectionThree
          title={locale.t("indices_what_is_block1.iux_what_is_header")}
          box1={
            <div>
              <p className="text-left">
                {locale.t("indices_what_is_block1.iux_what_is_discription_1")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("indices_what_is_block1.iux_what_is_discription_2")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("indices_what_is_block1.iux_what_is_discription_3")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("indices_what_is_block1.iux_what_is_discription_4")}
              </p>
            </div>
          }
          box2={
            <div>
              <p className="text-left">
                {locale.t("indices_what_is_block2.iux_what_is_discription_1")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("indices_what_is_block2.iux_what_is_discription_2")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("indices_what_is_block2.iux_what_is_discription_3")}
              </p>
              <br />
              <p className="text-left">
                {locale.t("indices_what_is_block2.iux_what_is_discription_4")}
              </p>
            </div>
          }
        />
        <SectionOne />
        <SectionTwo />
      </QueueAnim>
    );
  }
}
const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});
export default connect(mapStateToProps, null)(Indicesforex);
