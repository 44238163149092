import { Button, Modal, Collapse, Divider } from "antd";
import React, { Component } from "react";
import IconPanelPartner from "../../components/icon-panel/IconPanelPartner";
import TextWithCoverPartner from "../../components/text-with-cover/TextWithCoverPartner";
import RcQueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import PropTypes from "prop-types";
const Card = styled.div`
  padding: 16px;
  border-radius: 16px;
  height: 140px;
  width: 315px;
`;

const TITLE = "Partner | IUX";

const locale = require("react-redux-i18n").I18n;
const { Panel } = Collapse;

function LandingPartnerPage(props) {
  const forsuccess = [
    {
      icon:
        process.env.PUBLIC_URL + "/assets/images/partner/client-oriented.svg",
      title: locale.t("partner_help_1"),
      detail:
        "หากเพื่อนของคุณเข้าร่วมและทำการฝากเงิน พวกเขาจะได้รับ $30 จากเรา",
    },
    {
      icon:
        process.env.PUBLIC_URL +
        "/assets/images/partner/optimized-marketing.svg",
      title: locale.t("partner_help_2"),
      detail:
        "สำหรับเพื่อนทุกคนที่ฝากเงิน คุณจะได้รับ $10 จากเรา รวมสูงสุด 500$",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/data-anlyz.svg",
      title: locale.t("partner_help_3"),
      detail: "ทุกการซื้อของเพื่อนคุณ คุณจะได้รับ สูงสุด 15$/ Lot",
    },
  ];
  const autorebates = [
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/auto1.svg",
      title: locale.t("auto_rebate_1"),
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/auto2.svg",
      title: locale.t("auto_rebate_2"),
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/auto3.svg",
      title: locale.t("auto_rebate_3"),
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/auto4.svg",
      title: locale.t("auto_rebate_4"),
    },
  ];
  const whyiux = [
    {
      path: "/assets/images/partner/why1.svg",
      title: locale.t("partner_header_list_box_2.text_2"),
      detail:
        "ในฐานะผู้นำด้านโซลูชันการชำระเงินระดับโลก iUXmarket ได้รับความน่าเชื่อถือและเป็นที่ชื่นชอบของลูกค้าทั่วเอเชีย ผู้ใช้ทั้งหมดบอกเป็นเสียงเดียวกันว่า เรารวดเร็วที่สุด",
    },
    {
      path: "/assets/images/partner/why2.svg",
      title: locale.t("partner_header_list_box_2.text_3"),
      detail:
        "เราพัฒนาและลงทุนอย่างหนักเพื่อให้เป็นผู้นำในตลาดนี้ให้ได้ดังนั้นเราจะให้ความสำคัญไปที่ความรวดเร็วและการซื้อขายที่ไม่เกิด Slipage หัวใจของเราคือความเร็ว",
    },
    {
      path: "/assets/images/partner/why3.svg",
      title: locale.t("partner_header_list_box_2.text_4"),
      detail:
        "เราพัฒนาและลงทุนอย่างหนักเพื่อให้เป็นผู้นำในตลาดนี้ให้ได้ดังนั้นเราจะให้ความสำคัญไปที่ความรวดเร็วและการซื้อขายที่ไม่เกิด Slipage หัวใจของเราคือความเร็ว",
    },
    {
      path: "/assets/images/partner/why4.svg",
      title: locale.t("partner_header_list_box_2.text_5"),
      detail:
        "เราพัฒนาและลงทุนอย่างหนักเพื่อให้เป็นผู้นำในตลาดนี้ให้ได้ดังนั้นเราจะให้ความสำคัญไปที่ความรวดเร็วและการซื้อขายที่ไม่เกิด Slipage หัวใจของเราคือความเร็ว",
    },
    {
      path: "/assets/images/partner/why5.svg",
      title: locale.t("partner_header_list_box_2.text_6"),
      detail:
        "เราพัฒนาและลงทุนอย่างหนักเพื่อให้เป็นผู้นำในตลาดนี้ให้ได้ดังนั้นเราจะให้ความสำคัญไปที่ความรวดเร็วและการซื้อขายที่ไม่เกิด Slipage หัวใจของเราคือความเร็ว",
    },
    {
      path: "/assets/images/partner/why6.svg",
      title: locale.t("partner_header_list_box_2.text_1"),
      detail:
        "iUXmarket ช่วยให้ลูกค้าของคุณได้รับความปลอดภัยในการเทรดและเรื่องของเงินฝากที่มีการป้องกันเงินฝากสูงสุดถึง 100,000$",
    },
  ];
  const performance = [
    {
      icon: process.env.PUBLIC_URL + "/assets/images/payment/instantestore.png",
      detail: "โดยใช้ลิงก์แนะนำของคุณหรือไอคอนแชร์",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/payment/ipay88-logo.png",
      detail:
        "หากเพื่อนของคุณเข้าร่วมและทำการฝากเงิน พวกเขาจะได้รับ $30 จากเรา",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/payment/magestore.png",
      detail:
        "สำหรับเพื่อนทุกคนที่ฝากเงิน คุณจะได้รับ $10 จากเรา รวมสูงสุด 500$",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/payment/oddle.png",
      detail: "ทุกการซื้อของเพื่อนคุณ คุณจะได้รับ สูงสุด 15$/ Lot",
    },
  ];
  const steppartner = [
    {
      number: 1,
      title: locale.t("how_work_detail1"),
    },
    {
      number: 2,
      title: locale.t("how_work_detail2"),
    },
    {
      number: 3,
      title: locale.t("how_work_detail3"),
    },
    {
      number: 4,
      title: locale.t("how_work_detail4"),
    },
  ];
  const functionalities = [
    locale.t("function_detail_1"),
    locale.t("function_detail_2"),
    locale.t("function_detail_3"),
    locale.t("function_detail_4"),
    locale.t("function_detail_5"),
    locale.t("function_detail_6"),
  ];
  const autorebatesbottom = [
    locale.t("auto_rebate_detail1"),
    locale.t("auto_rebate_detail2"),
    locale.t("auto_rebate_detail3"),
    locale.t("auto_rebate_detail4"),
    locale.t("auto_rebate_detail5"),
  ];
  const alertContactPartner = () => {
    Modal.info({
      title: "iUXmarkets",
      content: (
        <div>
          <p>เข้าร่วมเป็นพันธมิตร จะต้องติดต่อโดยตรงเท่านั้น</p>
          <p>ติดต่อ : support@iuxmarkets.com</p>
        </div>
      ),
      onOk() {},
    });
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <RcQueueAnim className="bg-black">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <section key={2} className="container">
        <div className="row ">
          <div className="col-12 col-md-6 text-white">
            <h1 className="text-white">
              {locale.t("partner_header_introducing")}
            </h1>
            <div className="mt-20" />
            <p className=" fs-20">{locale.t("partner_des")}</p>

            <div className="mt-40">
              {" "}
              <p className="fs-20">{locale.t("title_ourib")}</p>
              <div className="mt-40">
                {functionalities.map((e, i) => (
                  <div className="d-flex mt-20">
                    <img
                      src="/assets/images/icons/true-yellow.svg"
                      alt="true"
                      className="mr-3"
                    />
                    <p className="fs-20">{e}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
            <img
              src="/assets/images/partner/partnership-international.png"
              alt="partnership-international"
            />
          </div>
        </div>
      </section>
      <section key={3} className="container mt-40">
        <div className="container-text-center mx-auto">
          <h1 className="text-white text-center">
            {locale.t("partner_header_introducing")}
          </h1>
          <div className="mt-40">
            <p className="fs-20 text-center text-white">
              {locale.t("partner_des2")}
            </p>
          </div>
          <div className="d-flex justify-content-center mt-40">
            <Link to={`/${localStorage.getItem("locale")}/formpartner`}>
              <Button className="btn-yellow-new-iux" onClick={scrollToTop}>
                Be a Marketing Affiliate
              </Button>
            </Link>
          </div>
        </div>
      </section>
      <section key={4} id="tutorial-iux">
        <div className="container pt-5 pb-5">
          <h3 className="mb-2 text-white text-center text-uppercase">
            {locale.t("partner_header_list_box_2.title")}
          </h3>
          <div className="row m-0 p-0 d-flex justify-content-around mt-5">
            {forsuccess.map((item, index) => {
              return <IconPanelPartner key={index} item={item} himg={index} />;
            })}
          </div>
        </div>
      </section>
      <section key={5} id="what-iux">
        <div className="container pt-5 pb-5">
          <h2 className="mb-5 text-white text-center text-uppercase ">
            {locale.t("offer_title")}
          </h2>
          <div className="row">
            <div className="col-12 col-md-6 d-flex justify-content-center">
              <div className="card-partner-transform ">
                <div
                  className="p-3 bg-yellow-partner"
                  style={{ borderRadius: "20px 20px 0 0" }}
                >
                  <div className="text-white fs-16 text-center ">
                    {locale.t("offer_title_box1")}
                  </div>
                </div>
                <div className=" text-center" style={{ padding: 40 }}>
                  <div style={{ marginBottom: 46 }}>
                    <img
                      src="/assets/images/icons/partner-b.svg"
                      alt="partner-b"
                    />
                    <div style={{ margin: "40px 0" }}>
                      <h3 className="mb-2 text-white text-center ">
                        {locale.t("offer_subtitle_box1")}
                      </h3>
                    </div>
                    <p className="text-gray fs-14 text-center">
                      {locale.t("offer_detail_box1")}
                    </p>
                  </div>

                  <Link to={`/${localStorage.getItem("locale")}/formpartner`}>
                    <Button
                      className="btn-yellow-outline "
                      onClick={scrollToTop}
                    >
                      Learn more
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center">
              <div className="card-partner-transform">
                <div
                  className="p-3 bg-yellow-partner"
                  style={{ borderRadius: "20px 20px 0 0" }}
                >
                  <div className="text-white fs-16 text-center ">
                    {locale.t("offer_title_box2")}
                  </div>
                </div>
                <div className=" text-center" style={{ padding: 40 }}>
                  <div style={{ marginBottom: 26 }}>
                    <img
                      src="/assets/images/icons/partner-b.svg"
                      alt="partner-b"
                    />
                    <div style={{ margin: "40px 0" }}>
                      <h3 className="mb-2 text-white text-center ">
                        {locale.t("offer_subtitle_box2")}
                      </h3>
                    </div>
                    <p className="text-gray text-center fs-14">
                      {locale.t("offer_detail_box2")}
                    </p>
                  </div>

                  <Link to={`/${localStorage.getItem("locale")}/formpartner`}>
                    <Button
                      className="btn-yellow-outline "
                      onClick={scrollToTop}
                    >
                      Learn more
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-40 text-center w-100">
              <p className="fs-12" style={{ color: "#6A6A6A" }}>
                {locale.t("offer_note")}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5 container-text-center mx-auto">
            <div className="bg-gray-new-iux p-4 p-md-5 border-round mt-5 w-100">
              <div className="row">
                <div className="col-12 col-md-8">
                  <h4 className="text-yellow-new-iux fs-20">
                    {locale.t("get_pro_title")}
                  </h4>
                  <p className="text-white mt-2">
                    {locale.t("get_pro_detail")}
                  </p>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end mt-3 mt-md-0">
                  <img
                    src="/assets/images/icons/iux-yellow.png"
                    alt="iux-yellow"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section key={6} id="why-iux" className="container mt-40 mb-40">
        <div className="container-text-center mx-auto text-center text-white">
          <h1 className="text-white">
            {" "}
            {locale.t("partner_header_introducing")}
          </h1>
          <div className="mt-40">
            <p>{locale.t("partner_des3")}</p>
          </div>
        </div>
        <div className="row mb-4 pt-5">
          {whyiux.map((item, index) => (
            <>
              <div className="col-md-6 col-lg-4 p-3">
                <div className="row">
                  <div className="col-12 col-md-4 align-items-center d-flex justify-content-center">
                    <img src={item.path} alt={`pic${index + 1}`} />
                  </div>
                  <div className="col-12 col-md-8 align-items-center d-flex">
                    <p className="text-white ">{item.title}</p>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </section>
      <section key={7} className="container">
        <div className=" mx-auto text-center text-white">
          <h1 className="text-white">{locale.t("auto_rebate_title")}</h1>
          <div className="row mt-80">
            {autorebates.map((e, i) => (
              <>
                <div className="col" key={i}>
                  <img src={e.icon} alt={e.title} />
                  <div className="mt-20">
                    <p className="text-yellow-new-iux mt-20">{e.title}</p>
                  </div>
                </div>
                {i !== autorebates.length - 1 && (
                  <img src="/assets/images/partner/arrow.svg" alt="arrow" />
                )}
              </>
            ))}
          </div>
          <div className="mt-80 d-flex flex-wrap-reverse justify-content-start justify-content-md-center">
            {autorebatesbottom.map((e, i) => (
              <div className="d-flex mr-4">
                <img
                  src="/assets/images/icons/true-yellow.svg"
                  alt="true"
                  className="mr-3"
                />
                <div className="text-wrap text-md-nowrap">{e}</div>
              </div>
            ))}
          </div>
          <div className="mt-80">
            <Link to={`/${localStorage.getItem("locale")}/formpartner`}>
              <Button className="btn-yellow-new-iux">
                Be a Marketing Affiliate
              </Button>
            </Link>
            <p className="text-gray text-center fs-14 mt-3">
              Terms and Conditions apply
            </p>
          </div>
        </div>
      </section>
      <section key={8}>
        <div className="container-text-center mx-auto text-center text-white">
          <h1 className="text-white">{locale.t("why_iux_title")}</h1>
          <div className="mt-40 fs-20">
            <p>{locale.t("why_iux_detail1")}</p>
            <div className="mt-40">
              <p>{locale.t("why_iux_detail2")}</p>
            </div>
          </div>
        </div>
      </section>
      <section key={9} id="tutorial-iux">
        <div className="container mt-80">
          <h1 className="mb-4 text-white text-center text-uppercase">
            {/* {locale.t("partner_header_list_box_1.title")} */}
            {locale.t("how_work_title")}
          </h1>
          <div className="container-text-center mx-auto">
            {steppartner.map((item, index) => {
              return (
                <>
                  <div className="d-flex h-100 w-100">
                    <div className="d-block d-md-flex align-items-center">
                      <h6
                        className="text-yellow-new-iux font-italic font-weight-bold mr-40"
                        style={{ fontSize: 64 }}
                      >
                        {index + 1}
                      </h6>
                      <p className="pl-1 pl-md-5 text-break text-white fs-16">
                        {item.title}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </RcQueueAnim>
  );
}
const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(
  mapStateToProps,

  null
)(LandingPartnerPage);
