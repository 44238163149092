import React from "react";
import PropTypes from "prop-types";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import { Helmet } from "react-helmet";
import RcQueueAnim from "rc-queue-anim";
import TextWithCover from "../../components/text-with-cover/TextWithCover";
import WindowsPage from "./windows";
import AndroidPage from "./android";
import IphonePage from "./iphone";
import MetatraderPage from "./mt4";
import { Link, Route, Switch } from "react-router-dom";
import styled from "styled-components";

const TITLE = "Metatrader | IUX";
const locale = require("react-redux-i18n").I18n;

const MenuItem = styled.div`
  padding: 0.8rem 1rem;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.borderBottom};
  white-space: pre;
`;

const Platforms = () => {
  const pathName = window.location.pathname;
  const [isIdex, setIndex] = React.useState(0);

  const menu = [
    {
      link: `/${localStorage.getItem("locale")}/platforms/mt4/windows`,
      text: "MT4 Windows",
    },
    {
      link: `/${localStorage.getItem("locale")}/platforms/mt4/android`,
      text: "MT4 Android",
    },
    {
      link: `/${localStorage.getItem("locale")}/platforms/mt4/iphone`,
      text: "MT4 iPhone / iPad",
    },
    {
      link: `/${localStorage.getItem("locale")}/platforms/mt4`,
      text: "Meta Trader 4",
    },
  ];

  const imgcover = [
    {
      title: "MT4 Windows",
      description: locale.t("cover.accover.description"),
      image: "/assets/images/covers/v2/cover-policy.png",
    },
    {
      title: "MT4 Android",
      description: locale.t("cover.accstd.description"),
      image: "/assets/images/covers/v2/cover-sefety.png",
    },
    {
      title: "MT4 iPhone / iPad",
      description: locale.t("cover.accbonus.description"),
      image: "/assets/images/covers/v2/cover-policy.png",
    },
    {
      title: "Meta Trader 4",
      description: locale.t("cover.accecn.description"),
      image: "/assets/images/covers/v2/cover-term.png",
    },
  ];

  React.useEffect(() => {
    const activeCover = () => {
      if (
        pathName === `/${localStorage.getItem("locale")}/platforms/mt4/windows`
      ) {
        setIndex(0);
      }
      if (
        pathName === `/${localStorage.getItem("locale")}/platforms/mt4/android`
      ) {
        setIndex(1);
      }
      if (
        pathName === `/${localStorage.getItem("locale")}/platforms/mt4/iphone`
      ) {
        setIndex(2);
      }
      if (pathName === `/${localStorage.getItem("locale")}/platforms/mt4`) {
        setIndex(3);
      }
    };
    activeCover();
  }, [pathName]);

  return (
    <div>
      <RcQueueAnim delay={300}>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="bg-white">
          <TextWithCover
            key={1}
            imagePath={process.env.PUBLIC_URL + imgcover[isIdex].image}
            title={imgcover[isIdex].title}
            content={
              <>
                <div>
                  <p className="text-center">{imgcover[isIdex].description}</p>
                  {/* {renderButtonCover()} */}
                </div>
              </>
            }
          />
          <div className="sub-menu-page">
            <div className="container">
              <div className="d-flex overflow-auto scroll-menu-hide">
                {menu.map((e, i) => (
                  <Link to={e.link}>
                    <MenuItem
                      onClick={() => setIndex(i)}
                      color={e.link === pathName ? "#009EFF" : "#b7b4b4"}
                      borderBottom={
                        e.link === pathName
                          ? "2px solid #009EFF"
                          : "2px solid #ff000000"
                      }
                    >
                      {e.text}
                    </MenuItem>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <Switch>
            <Route
              path={`/:lang/platforms/mt4/windows`}
              render={() => {
                return <WindowsPage />;
              }}
            />
            <Route
              path={`/:lang/platforms/mt4/iphone`}
              render={() => {
                return <IphonePage />;
              }}
            />
            <Route
              path={`/:lang/platforms/mt4/android`}
              render={() => {
                return <AndroidPage />;
              }}
            />
            <Route
              path={`/:lang/platforms/mt4`}
              render={() => {
                return <MetatraderPage />;
              }}
            />
          </Switch>
          <div>
            <FooterLandingPage locale={locale} />
          </div>
        </div>
      </RcQueueAnim>
    </div>
  );
};

export default Platforms;
