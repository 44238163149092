import React, { useState, useEffect } from "react";
import { TransferContent, HistoryContent } from "../../../components";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Select,
  Button,
  Modal,
  Form,
  message,
  Progress,
  Input,
  Pagination,
  Spin,
} from "antd";
import { post, get } from "../../../helper/request";
import { getUser, getAccount, updateBalance } from "../../../actions/user";
import TransferHistory from "./TransferHistory";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { renderApiDomain } from "../../../helper/renderApiDomain";

const TITLE = "Transfer | IUX";
const locale = require("react-redux-i18n").I18n;

const { Option } = Select;
const { confirm } = Modal;

function Transfer(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const accountList = useSelector((state) => state.account.accountTypeList);
  const [history, setHistory] = useState([]);
  const [init, setInit] = useState(false);
  const [selectTo, setSelectTo] = useState(0);
  const [accForm, setAccForm] = useState(null);
  const [accType, setAccType] = useState(null);
  const [accTypeTo, setAccTypeTo] = useState(null);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const fetchData = () => {
    setLoading(true);
    get(
      renderApiDomain() +
        "user/account/trade/transfer" +
        `?limit=${10}&page=${page}`
    )
      .then((res) => {
        setHistory(res.data.data);
        setTotal(res.data.total);
        setInit(true);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setInit(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    // if (!init) {
    fetchData();
    // }
  }, [page]);

  function onSelectTo(value) {
    let acc_type = props.user.accounts.find((e) => e.id === value);
    setAccTypeTo(acc_type);
    setSelectTo(parseFloat(value));
  }

  function onSelectAccount(value) {
    let acc_type = props.user.accounts.find((e) => e.id === value);

    setAccType(acc_type);
  }

  function checkTransfer(rule, value) {
    let isAmount = props.user.accounts.find((item) => item.id === accForm);

    let accountTypePRO = props.user.accounts.filter(
      (item) => item.type === "real" && item.account_type === "Pro"
    );
    let accountTypeRaw = props.user.accounts.filter(
      (item) => item.type === "real" && item.account_type === "Raw"
    );
    const filterTypePRO = accountTypePRO.filter((item) => item.id === selectTo);
    const filterTypeRaw = accountTypeRaw.filter((item) => item.id === selectTo);
    const filterTypePROfrom = accountTypePRO.filter(
      (item) => item.id === accForm
    );
    const filterTypeRawfrom = accountTypeRaw.filter(
      (item) => item.id === accForm
    );

    if (value > isAmount?.current_balance) {
      return Promise.reject(locale.t("withdraw_error.t_error"));
    } else if (value === undefined || parseFloat(value) === 0 || value === "") {
      return Promise.reject(locale.t("dashboardWallet.rq_amount"));
    } else {
      if (filterTypePRO.length >= 1) {
        if (
          value >=
          accountList.find((e) => e.name === "Pro" && e.type === "real")
            ?.minimum_deposit
        ) {
          return Promise.resolve();
        } else if (
          filterTypePROfrom.length > 0 ||
          filterTypeRawfrom.length > 0
        ) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            locale.t("dashboardInnerTransfer.error.title_PRO") +
              "$" +
              accountList.find((e) => e.name === "Pro" && e.type === "real")
                ?.minimum_deposit
          );
        }
      } else if (filterTypeRaw.length >= 1) {
        if (
          value >=
          accountList.find((e) => e.name === "Raw" && e.type === "real")
            ?.minimum_deposit
        ) {
          return Promise.resolve();
        } else if (
          filterTypePROfrom.length > 0 ||
          filterTypeRawfrom.length > 0
        ) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            locale.t("dashboardInnerTransfer.error.title_Raw") +
              "$" +
              accountList.find((e) => e.name === "Pro" && e.type === "real")
                ?.minimum_deposit
          );
        }
      } else {
        if (value === undefined || value === 0) {
          return Promise.reject(locale.t("dashboardWallet.rq_amount"));
        } else {
          return Promise.resolve();
        }
      }
    }
  }

  function onFinish(values) {
    const accounttype = props.user.accounts.find(
      (e) => e.id === values.account_to
    );

    confirm({
      title: locale.t("dashboardInnerTransfer.confirm_dialog.title"),
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        return new Promise((resolve, reject) => {
          post(renderApiDomain() + "user/account/transfer", {
            from_id: values.accountFrom,
            to_id: values.account_to,
            amount:
              accounttype.account_type === "Cent"
                ? values.amount / 100
                : values.amount,
          })
            .then((res) => {
              message.success({
                title: "Success",
                content: "Update success!",
              });
              onSync(values.accountFrom);
              onSync(values.account_to);
              setAccType(null);
              setAccTypeTo(null);
              setAccForm(null);
              setSelectTo(0);
              dispatch(getAccount());
              form.resetFields();
              resolve();
              fetchData();
            })
            .catch((e) => {
              console.log(e.response.data.error);
              if (e.response.data.error) {
                message.error(e.response.data.error);
              }
              reject();
            });
        });
      },
    });
  }

  const onSync = async (account) => {
    setLoading(true);
    try {
      get(renderApiDomain() + `user/account/sync/${account}`)
        .then((res) => {
          message.success("Sync Success..");
          const indexAcc = props.user.accounts.findIndex(
            (e) => e.id === account
          );

          let acc = props.user.accounts;
          acc[indexAcc].current_balance = res.data.data.balance;
          acc[indexAcc].current_credit = res.data.data.credit;
          acc[indexAcc].loading = false;

          dispatch(updateBalance(acc));
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          try {
            message.error(e.response.data.error);
          } catch (error) {
            message.error(error.message);
          }
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function renderSelectForm() {
    if (accTypeTo) {
      if (accTypeTo.account_type === "Cent") {
        return props.user.accounts.filter(
          (item) => item.type === "real" && item.account_type === "Cent"
        );
      } else if (
        accTypeTo.account_type !== "Pro" &&
        accTypeTo.account_type !== "Raw"
      ) {
        return props.user.accounts.filter(
          (item) =>
            item.type === "real" &&
            item.account_type !== "Pro" &&
            item.account_type !== "Raw" &&
            item.account_type !== "Cent"
        );
      } else {
        return props.user.accounts.filter(
          (item) => item.type === "real" && item.account_type !== "Cent"
        );
      }
    } else {
      return props.user.accounts.filter((item) => item.type === "real");
    }
  }

  function renderSelectTo() {
    if (accType) {
      if (accType.account_type === "Cent") {
        return props.user.accounts.filter(
          (item) => item.type === "real" && item.account_type === "Cent"
        );
      } else if (
        accType.account_type === "Pro" ||
        accType.account_type === "Raw"
      ) {
        return props.user.accounts.filter(
          (item) =>
            item.type === "real" &&
            (item.account_type === "Pro" || item.account_type === "Raw")
        );
      } else {
        return props.user.accounts.filter(
          (item) => item.type === "real" && item.account_type !== "Cent"
        );
      }
    } else {
      return props.user.accounts.filter((item) => item.type === "real");
    }
  }

  const removeLeadingZeros = (value) => {
    return value.replace(/^0+/, "");
  };
  const handleKeyPress = (event) => {
    const input = event.target.value + event.key;

    // Regular expression to check if the input is a two-digit number with optional decimal point
    // const regex = /^\d*\.?\d{0,3}$/;
    const regex = /^\d+(\.\d{0,2})?$/;

    if (!regex.test(input)) {
      event.preventDefault(); // Prevents entering the invalid key
    }
  };
  return (
    <div
      className="hidden-overflow user bg-white p-4 internal-transfer"
      style={{ borderRadius: 20 }}
    >
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>{" "}
      <p className="text-header-account-wallet">
        {locale.t("dashboardInnerTransfer.title")}
      </p>
      {props.user.accounts.length > 1 ? (
        <>
          <div className="hide-ipad mt-4">
            <TransferContent className="col-md-12 mb-4">
              <Form form={form} onFinish={onFinish} layout={"vertical"}>
                <div
                  className="mb-4 bg-iux attention-bg"
                  style={{ padding: "14px" }}
                >
                  <p className="text-gray fs-12 fw-700">
                    {locale.t("dashboardInnerTransfer.warningTitle")}
                  </p>
                  <div
                    style={{ fontSize: "10px", marginTop: 10 }}
                    className="text-gray "
                  >
                    <div>
                      <p>
                        - {locale.t("dashboardInnerTransfer.warningSubTitle1")}
                      </p>
                    </div>
                    <div>
                      <p>
                        - {locale.t("dashboardInnerTransfer.warningSubTitle2")}
                      </p>
                    </div>
                    <div>
                      <p>
                        - {locale.t("dashboardInnerTransfer.warningSubTitle3")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <Form.Item
                      className="from-transfer"
                      name="accountFrom"
                      label={locale.t("dashboardInnerTransfer.input1")}
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.rq_account"),
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        className="pt-2 input-selected user-tranfer"
                        placeholder={locale.t(
                          "dashboardInnerTransfer.input1Placeholder"
                        )}
                        allowClear
                        onClear={() => {
                          setAccType(null);
                          // setAccTypeTo(null);
                          // form?.resetFields();
                        }}
                        onChange={(e) => {
                          onSelectAccount(e);
                          setAccForm(e);
                          form.setFieldsValue({ amount: "" });
                        }}
                      >
                        {renderSelectForm().map((account) => {
                          return (
                            account.id !== selectTo && (
                              <Option key={account.id} value={account.id}>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    {` ${account.account_group.display} ${account.account_number}`}
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      color:
                                        account.current_balance > 0
                                          ? "green"
                                          : "gray",
                                    }}
                                  >
                                    {`${
                                      account.account_type === "Cent"
                                        ? `${account.current_balance} USC`
                                        : account.current_balance.toLocaleString(
                                            "en-US",
                                            {
                                              style: "currency",
                                              currency: "USD",
                                            }
                                          )
                                    }`}
                                  </div>
                                </div>
                              </Option>
                            )
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    {/* <h5 className="pt-2 pb-2">{}</h5> */}
                    <Form.Item
                      className="from-transfer "
                      name="amount"
                      label={locale.t("dashboardInnerTransfer.amount")}
                      rules={[
                        {
                          required: true,
                          validator: checkTransfer,
                        },
                      ]}
                    >
                      <Input
                        className="pt-2 pb-2 w-100 mt-2"
                        // prefix="$"
                        // suffix="USD"
                        type="number"
                        step={0.01}
                        placeholder={locale.t(
                          "dashboardInnerTransfer.input3Placeholder"
                        )}
                        onKeyPress={handleKeyPress}
                        // onChange={(event) => {
                        //   form.setFieldsValue({
                        //     amount: removeLeadingZeros(event.target.value),
                        //   });
                        // }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-4">
                    <Form.Item
                      className="from-transfer"
                      name="account_to"
                      label={locale.t("dashboardInnerTransfer.input2")}
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.rq_account"),
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        className="pt-2 input-selected"
                        placeholder={locale.t(
                          "dashboardInnerTransfer.input2Placeholder"
                        )}
                        allowClear
                        onChange={onSelectTo}
                        // disabled={accForm === null || accForm === undefined}
                        // defaultValue="Choose account to"
                        onClear={() => {
                          // setAccType(null);
                          setAccTypeTo(null);
                          // form.resetFields();
                        }}
                      >
                        {renderSelectTo().map((account) => {
                          return (
                            account.id !== accForm && (
                              <Option key={account.id} value={account.id}>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    {`${account?.account_group?.display} ${account.account_number}`}
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      color:
                                        account.current_balance > 0
                                          ? "green"
                                          : "gray",
                                    }}
                                  >
                                    {`${
                                      account.account_type === "Cent"
                                        ? `${account.current_balance} USC`
                                        : account.current_balance.toLocaleString(
                                            "en-US",
                                            {
                                              style: "currency",
                                              currency: "USD",
                                            }
                                          )
                                    }`}
                                  </div>
                                </div>
                              </Option>
                            )
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <Button
                    // style={{ textTransform: "uppercase" }}
                    className="mt-4 btn-primary-new-iux"
                    htmlType="submit"
                    type="primary"
                  >
                    {locale.t("dashboardInnerTransfer.tab1")}
                  </Button>
                </div>
              </Form>
            </TransferContent>

            <HistoryContent>
              {loading ? (
                <div style={{ textAlign: "center", marginTop: "32px" }}>
                  <Spin />
                </div>
              ) : (
                <>
                  <TransferHistory
                    history={history}
                    locale={locale}
                    // scroll={{ y: 500 }}
                  />
                  <div style={{ marginTop: "18px" }}>
                    <Pagination
                      defaultCurrent={1}
                      current={page}
                      onChange={(page) => setPage(page)}
                      total={total}
                      pageSize={10}
                      style={{ textAlign: "end" }}
                      showTotal={false}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              )}
            </HistoryContent>
          </div>
        </>
      ) : (
        <div
          style={{ height: "80vh" }}
          className="d-flex justify-content-center align-items-center flex-column"
        >
          <img
            src="/assets/images/icons/tranfers-icons.svg"
            alt="tranfer-icons"
          />
          <p className="mb-4 mt-2">
            {locale.t("dashboardWallet.transferNomore")}
          </p>
          <Link
            to={`/${localStorage.getItem("locale")}/wallet/account/openaccount`}
            style={{ padding: 0 }}
          >
            <Button
              // style={{ textTransform: "uppercase" }}
              className="mt-4 btn-primary-new-iux"
              // htmlType="submit"
              type="primary"
            >
              <p className="">+ {locale.t("dashboardWallet.createAccount")}</p>
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, null)(Transfer);
