import React, { Component } from "react";
import { Row, Button } from "antd";
import { Link } from "react-router-dom";
import QueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import SectionOne from "../component/SectionOne";
import SectionTwo from "../component/SectionTwo";
import SectionThree from "../component/SectionThree";
const TITLE = "Futures - Market | IUX";
const locale = require("react-redux-i18n").I18n;

export class Futuresforex extends Component {
  render() {
    return (
      <QueueAnim>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>{" "}
        <SectionThree
          title={locale.t("futures_what_is_block1.iux_what_is_header")}
          box1={locale.t("futures_what_is_block1.iux_what_is_discription_1")}
          box2={locale.t("futures_what_is_block2.iux_what_is_discription_1")}
        />
        <SectionOne />
        <SectionTwo />
      </QueueAnim>
    );
  }
}
const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});
export default connect(mapStateToProps, null)(Futuresforex);
