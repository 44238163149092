import { Button, Modal, Collapse, Divider } from "antd";
import React, { Component } from "react";
import IconPanelPartner from "../../components/icon-panel/IconPanelPartner";
import TextWithCoverPartner from "../../components/text-with-cover/TextWithCoverPartner";
import RcQueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import PropTypes from "prop-types";
const Card = styled.div`
  padding: 16px;
  border-radius: 16px;
  height: 140px;
  width: 315px;
`;

const TITLE = "Partner | IUX";
const inputlabel = {
  height: "42px",
  background: "#242424",
  border: "1px solid #3c3c3c",
  "&:hover": {
    border: "1px solid #1f7bb8",
    boxShadow: "unset",
  },
};
const locale = require("react-redux-i18n").I18n;
const { Panel } = Collapse;

function GuildlinePage(props) {
  const forsuccess = [
    {
      icon:
        process.env.PUBLIC_URL + "/assets/images/partner/client-oriented.svg",
      title: "Client-oriented",
      detail:
        "หากเพื่อนของคุณเข้าร่วมและทำการฝากเงิน พวกเขาจะได้รับ $30 จากเรา",
    },
    {
      icon:
        process.env.PUBLIC_URL +
        "/assets/images/partner/optimized-marketing.svg",
      title: "Optimized Marketing",
      detail:
        "สำหรับเพื่อนทุกคนที่ฝากเงิน คุณจะได้รับ $10 จากเรา รวมสูงสุด 500$",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/data-anlyz.svg",
      title: "24/7 Data And Analytics",
      detail: "ทุกการซื้อของเพื่อนคุณ คุณจะได้รับ สูงสุด 15$/ Lot",
    },
  ];
  const autorebates = [
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/auto1.svg",
      title: "Affiliate Comission",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/auto2.svg",
      title: "IUXMARKETS Affiliate",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/auto3.svg",
      title: "Auto Rebate",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/partner/auto4.svg",
      title: "Client",
    },
  ];
  const whyiux = [
    {
      path: "/assets/images/partner/why1.svg",
      title: locale.t("partner_header_list_box_2.text_2"),
      detail:
        "ในฐานะผู้นำด้านโซลูชันการชำระเงินระดับโลก iUXmarket ได้รับความน่าเชื่อถือและเป็นที่ชื่นชอบของลูกค้าทั่วเอเชีย ผู้ใช้ทั้งหมดบอกเป็นเสียงเดียวกันว่า เรารวดเร็วที่สุด",
    },
    {
      path: "/assets/images/partner/why2.svg",
      title: locale.t("partner_header_list_box_2.text_3"),
      detail:
        "เราพัฒนาและลงทุนอย่างหนักเพื่อให้เป็นผู้นำในตลาดนี้ให้ได้ดังนั้นเราจะให้ความสำคัญไปที่ความรวดเร็วและการซื้อขายที่ไม่เกิด Slipage หัวใจของเราคือความเร็ว",
    },
    {
      path: "/assets/images/partner/why3.svg",
      title: locale.t("partner_header_list_box_2.text_4"),
      detail:
        "เราพัฒนาและลงทุนอย่างหนักเพื่อให้เป็นผู้นำในตลาดนี้ให้ได้ดังนั้นเราจะให้ความสำคัญไปที่ความรวดเร็วและการซื้อขายที่ไม่เกิด Slipage หัวใจของเราคือความเร็ว",
    },
    {
      path: "/assets/images/partner/why4.svg",
      title: locale.t("partner_header_list_box_2.text_5"),
      detail:
        "เราพัฒนาและลงทุนอย่างหนักเพื่อให้เป็นผู้นำในตลาดนี้ให้ได้ดังนั้นเราจะให้ความสำคัญไปที่ความรวดเร็วและการซื้อขายที่ไม่เกิด Slipage หัวใจของเราคือความเร็ว",
    },
    {
      path: "/assets/images/partner/why5.svg",
      title: locale.t("partner_header_list_box_2.text_6"),
      detail:
        "เราพัฒนาและลงทุนอย่างหนักเพื่อให้เป็นผู้นำในตลาดนี้ให้ได้ดังนั้นเราจะให้ความสำคัญไปที่ความรวดเร็วและการซื้อขายที่ไม่เกิด Slipage หัวใจของเราคือความเร็ว",
    },
    {
      path: "/assets/images/partner/why6.svg",
      title: locale.t("partner_header_list_box_2.text_1"),
      detail:
        "iUXmarket ช่วยให้ลูกค้าของคุณได้รับความปลอดภัยในการเทรดและเรื่องของเงินฝากที่มีการป้องกันเงินฝากสูงสุดถึง 100,000$",
    },
  ];
  const performance = [
    {
      icon: process.env.PUBLIC_URL + "/assets/images/payment/instantestore.png",
      detail: "โดยใช้ลิงก์แนะนำของคุณหรือไอคอนแชร์",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/payment/ipay88-logo.png",
      detail:
        "หากเพื่อนของคุณเข้าร่วมและทำการฝากเงิน พวกเขาจะได้รับ $30 จากเรา",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/payment/magestore.png",
      detail:
        "สำหรับเพื่อนทุกคนที่ฝากเงิน คุณจะได้รับ $10 จากเรา รวมสูงสุด 500$",
    },
    {
      icon: process.env.PUBLIC_URL + "/assets/images/payment/oddle.png",
      detail: "ทุกการซื้อของเพื่อนคุณ คุณจะได้รับ สูงสุด 15$/ Lot",
    },
  ];
  const steppartner = [
    {
      number: 1,
      title:
        "We will review your application and get back to you within one business day. Join our Affiliate Partner by follow our simple application process",
    },
    {
      number: 2,
      title:
        "After signing up, one of our team members will contact and guide you through the process on how to start your referrals.",
    },
    {
      number: 3,
      title:
        "Don’t worry! We will update and support you along your growth journey.",
    },
    {
      number: 4,
      title:
        "After finishing reviewing your referred clients, the rewards will automatically appear in your wallet (only for eligible clients)",
    },
  ];
  const functionalities = [
    "Real time commissions",
    "Multi-level IB structure",
    "Flexible commissions setting",
    "Easy and swift deposit and withdrawal",
    "IB relationship hierarchy",
    "Client activity report",
  ];
  const autorebatesbottom = [
    "Schedule automatic client rebates",
    "Choose to control rebates manually",
    "Rely on us for your Back Office operations",
    "Easily attract clients by awarding rebates",
    "Adjust the percentage of rebate given to each client",
  ];
  const alertContactPartner = () => {
    Modal.info({
      title: "iUXmarkets",
      content: (
        <div>
          <p>เข้าร่วมเป็นพันธมิตร จะต้องติดต่อโดยตรงเท่านั้น</p>
          <p>ติดต่อ : support@iuxmarkets.com</p>
        </div>
      ),
      onOk() {},
    });
  };

  return (
    <RcQueueAnim className="bg-black">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <section key={2} className="container">
        <div className="container-text-center mx-auto text-white text-center">
          <h1 className="text-white text-uppercase">
            {locale.t("guildeline_header")}
          </h1>
          <div className="mt-20" />
          <p className=" fs-20 ">{locale.t("guildeline_sub_header1")}</p>
          <div className="mt-20" />
          <p className="fs-20">{locale.t("guildeline_sub_header2")}</p>
          <div className="mt-20" />
          <p className="fs-20">
            {locale.t("guildeline_sub_header3_1")}{" "}
            <span className="text-yellow-new-iux">
              {locale.t("guildeline_sub_header3_2")}
            </span>{" "}
            {locale.t("guildeline_sub_header3_3")}
          </p>
          <div className="mt-20" />
          <Input
            className="login "
            name="email"
            style={inputlabel}
            // placeholder={locale.t("login_your_email")}
          />
        </div>
      </section>
      <section key={3} className="container mt-40">
        <div className="container-text-center mx-auto">
          <div className="row">
            <div className="col-1">
              {" "}
              <img
                src="/assets/images/partner/plus.svg"
                alt="pls"
                className="pt-2"
              />
            </div>
            <div className="col-11">
              <h4 className="text-white ">{locale.t("guildeline_topic_1")}</h4>{" "}
              <div className="mt-20">
                <p className="fs-20  text-gray">
                  {locale.t("guildeline_topic1_des")}
                </p>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic1_1")}
                  </div>
                </div>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic1_2")}
                  </div>
                </div>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic1_3")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-1">
              {" "}
              <img
                src="/assets/images/partner/plus.svg"
                alt="pls"
                className="pt-2"
              />
            </div>
            <div className="col-11">
              <h4 className="text-white ">{locale.t("guildeline_topic_2")}</h4>{" "}
              <div className="mt-20">
                <p className="fs-20  text-gray">
                  {locale.t("guildeline_topic2_des")}
                </p>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic2_1")}
                  </div>
                </div>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic2_2")}
                  </div>
                </div>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic2_3")}
                  </div>
                </div>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic2_4")}
                  </div>
                </div>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic2_5")}
                  </div>
                </div>
                <p className="fs-20 mt-20 text-gray">
                  {locale.t("guildeline_topic2_des2")}
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-1">
              {" "}
              <img
                src="/assets/images/partner/plus.svg"
                alt="pls"
                className="pt-2"
              />
            </div>
            <div className="col-11">
              <h4 className="text-white "> {locale.t("guildeline_topic_3")}</h4>{" "}
              <div className="mt-20">
                <p className="fs-20  text-gray">
                  {locale.t("guildeline_topic3_des1")}
                </p>
                <p className="fs-20  text-gray mt-20">
                  {locale.t("guildeline_topic3_des2")}
                </p>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic3_1")}
                    <p className="mt-20">
                      {locale.t("guildeline_sub_topic3_1i")}
                    </p>
                    <p className="mt-20">
                      {locale.t("guildeline_sub_topic3_1ii")}
                    </p>
                  </div>
                </div>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic3_2")}
                  </div>
                </div>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic3_3")}
                  </div>
                </div>
                <p className="fs-20 mt-20 text-gray">
                  {locale.t("guildeline_topic3_des3")}
                </p>
              </div>
            </div>{" "}
          </div>{" "}
          <div className="row mt-20">
            <div className="col-1">
              <img
                src="/assets/images/partner/plus.svg"
                alt="pls"
                className="pt-2"
              />
            </div>
            <div className="col-11">
              <h4 className="text-white "> {locale.t("guildeline_topic_4")}</h4>
              <p className="mt-20 fs-20 text-gray">
                {locale.t("guildeline_topic4_des1")}
              </p>
              <p className="mt-20 fs-20 text-gray">
                {locale.t("guildeline_topic4_des2")}
              </p>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-1">
              {" "}
              <img
                src="/assets/images/partner/plus.svg"
                alt="pls"
                className="pt-2"
              />
            </div>
            <div className="col-11">
              <h4 className="text-white ">
                {" "}
                {locale.t("guildeline_topic_5")}{" "}
              </h4>
              <div className="mt-20">
                <p className="fs-20  text-gray">
                  {locale.t("guildeline_sub_topic5_1")}
                </p>
                <p className="fs-20 mt-20 text-gray">
                  {" "}
                  {locale.t("guildeline_sub_topic5_2")}
                </p>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic5_2_1")}
                  </div>
                </div>
                <div className="fs-20 text-gray mt-20 d-flex">
                  ●
                  <div className="ml-3">
                    {locale.t("guildeline_sub_topic5_2_2")}
                    <p className="mt-20">
                      {locale.t("guildeline_sub_topic5_2_3")}
                    </p>
                    <p className="mt-20">
                      {locale.t("guildeline_sub_topic5_2_4")}
                    </p>
                  </div>
                </div>
                <p className="fs-20 mt-20 text-gray">
                  {locale.t("guildeline_sub_topic5_3")}
                </p>
                <p className="fs-20 mt-20 text-gray">
                  {locale.t("guildeline_sub_topic5_4")}
                </p>
                <p className="fs-20 mt-20 text-gray">
                  {locale.t("guildeline_sub_topic5_5")}
                </p>
                <p className="fs-20 mt-20 text-gray">
                  {locale.t("guildeline_sub_topic5_6")}
                </p>
                <p className="fs-20 mt-20 text-gray">
                  {locale.t("guildeline_sub_topic5_7")}
                </p>
                <p className="fs-20 mt-20 text-gray">
                  {locale.t("guildeline_sub_topic5_8")}
                </p>
                <p className="fs-20 mt-20 text-gray">
                  {locale.t("guildeline_sub_topic5_9")}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-40">
            <Link to={`/${localStorage.getItem("locale")}/formpartner`}>
              <Button className="btn-yellow-new-iux">
                Be a Marketing Affiliate
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </RcQueueAnim>
  );
}
const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(
  mapStateToProps,

  null
)(GuildlinePage);
