import React, {Component} from "react";
import SpaceCard from "../../../components/space-card/SpaceCard";
import RcQueueAnim from "rc-queue-anim";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

const TITLE = "Metatrader | IUX";
const locale = require("react-redux-i18n").I18n;

class WindowsPage extends Component {
  constructor(props) {
    super(props);
    const listGiving = [
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-average.svg",
        title: locale.t("platform_iphone_section_2.label_1"),
      },
      {
        icon: process.env.PUBLIC_URL + "/assets/images/icons/iux-currency.svg",
        title: locale.t("platform_iphone_section_2.label_2"),
      },
    ];

    const iphoneInfo = {
      title: "MetaTrader 4",
      listInfo: [
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_1"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_2"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_3"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_4"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_5"),
        },
        {
          text: locale.t("social_trade_eiloy_social_side_box.text_6"),
        },
      ],
    };

    const space = "Windows OS";
    this.state = {
      current: props.current,
      listGiving: listGiving,
      iphoneInfo: iphoneInfo,
      space: space,
    };
  }

  render() {
    return (
      <RcQueueAnim delay={300}>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="bg-white">
          <section id="info" className="background-section-platform">
            <div className="container">
              <div className="row justify-content-center w-100">
                <div className="col-md-6">
                  <div className="margin-description-platform text-left">
                  {locale.t("platform_windows_description")}
                  </div>
                </div>
                <div className="col-md-6">
                  <img
                    className="img-platform-all-responsive"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/platforms-mac.png"
                    }
                    alt="img"
                  />
                </div>
                {/* <InfoBox data={this.state.iphoneInfo} textbutton="Metatrader 4" /> */}
              </div>
            </div>
          </section>
          <section className="bg-white pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <h3 className="text-primary-iux">
                    {locale.t("why_platform.iphone.title1")}
                    {locale.t("why_platform.iphone.title2")}
                  </h3>
                  <h2 className="font-responsive-subtitle">
                    {locale.t("why_platform.iphone.subTitle")}
                  </h2>
                  <p>{locale.t("why_platform.iphone.description")}</p>
                </div>
                <div className="col-md-4 mt-4 mb-4">
                  <SpaceCard space={this.state.space}/>
                  <div className="mt-4">
                    <a
                      href="https://download.mql5.com/cdn/web/iux.markets.limited/mt5/iuxmarkets5setup.exe"
                      rel="noopener noreferrer"
                      download
                    >
                      <button className="w-100 windows-button">
                      <span>
                        {locale.t("platform_windows_header.button_text")}
                      </span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </RcQueueAnim>
    );
  }
}

const mapStateToProps = ({i18n}) => ({
  locale: i18n.locale,
});

export default connect(mapStateToProps, null)(WindowsPage);
