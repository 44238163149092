import { Button, Row, Col } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { get } from "../../helper/request";
import RcQueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import TextWithCover from "../../components/text-with-cover/TextWithCover";
const TITLE = "Lot Back | IUX";
const locale = require("react-redux-i18n").I18n;

const Background = styled.div`
  background-size: cover !important;
`;

const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${(props) => props.bgcolor};
`;

const CardRank = styled.div`
  width: 100%;
  margin-right: 10px;
  color: #fff;
  background-color: black;
  display: flex;
  padding: 1rem 1rem;
  justify-content: center;
`;

const TextRank = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Border = styled.div`
  width: 1px;
  height: 40px;
  background-color: gray;
  margin: auto;
`;
const BorderVertical = styled.div`
  width: 60px;
  height: 1px;
  background-color: gray;
  margin-top: 70px;
`;

const Box = styled.div`
  background-color: #14181a;
  border-radius: 20px;
  width: 409px;
`;

const TextIUX = styled.div`
  font-size: ${(props) => props.fontSize};
  color: #1cb894;
  font-weight: bold;
`;

const textStyle = {
  color: "white",
  textAlign: "center",
};

function LotbackBonus() {
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <RcQueueAnim className="bg-black">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <section key={1} id="bonus" style={{ width: "100%" }}>
        <TextWithCover
          key={3}
          styles={{ backgroundPosition: "center !important" }}
          // title={locale.t('cover_loyalty_header')}
          content={
            <>
              <div>
                <h3
                  className="font-bold-iux text-white"
                  onClick={executeScroll}
                >
                  Lot Back Program
                </h3>
                <p className="text-white">
                  {locale.t("cover_loyalty_content")}
                </p>
              </div>
              <div>
                <Link to={`/${localStorage.getItem("locale")}/login`}>
                  <Button className="mt-4 btn-primary-new-iux text-uppercase">
                    {locale.t("cover_loyalty_button")}
                  </Button>
                </Link>
              </div>
            </>
          }
        />
      </section>
      <section key={2}>
        <div className="bg-black">
          <div className="container pt-4 pb-5 pr-5 pl-5">
            <div ref={myRef}>
              <h4 className="mt-4 text-white font-bold-iux text-center">
                {locale.t("loyalty_rewards.header")}
              </h4>
              <div className="pb-4">
                <p className="text-of-white text-center">
                  {locale.t("loyalty_rewards.title")}
                </p>
              </div>
              <div className="row mt-4 justify-center">
                <div className="col-md-12 col-lg-3 mb-4 mb-lg-0">
                  <CardRank ris>
                    <div className="w-100 d-flex flex-column align-items-center justify-center">
                      <div className="text-gray">
                        <img
                          src="/assets/images/icons/rigsingstar.svg"
                          alt="rigsingstar"
                        />
                      </div>
                      <div className="text-gray mt-4">Status</div>
                      <TextRank color="#A0A0A0" fontSize={32}>
                        Rising star
                      </TextRank>
                    </div>
                  </CardRank>
                </div>
                <div className="d-none d-md-none d-lg-flex">
                  <BorderVertical />
                </div>
                <div className="col-md-12 col-lg-3 mb-4 mb-lg-0">
                  <CardRank ris>
                    <div className="w-100 d-flex flex-column align-items-center justify-center">
                      <div className="text-gray">
                        <img
                          src="/assets/images/icons/champion.svg"
                          alt="rigsingstar"
                        />
                      </div>
                      <div className="text-gray mt-4">Status</div>
                      <TextRank color="#DCBE59" fontSize={32}>
                        Champion
                      </TextRank>
                    </div>
                  </CardRank>
                </div>
                <div className="d-none d-md-none d-lg-flex">
                  <BorderVertical />
                </div>
                <div className="col-md-12 col-lg-3 mb-4 mb-lg-0">
                  <CardRank ris>
                    <div className="w-100 d-flex flex-column align-items-center justify-center">
                      <div className="text-gray">
                        <img
                          src="/assets/images/icons/elite.svg"
                          alt="rigsingstar"
                        />
                      </div>
                      <div className="text-gray mt-4">Status</div>
                      <TextRank color="#D63F3E" fontSize={32}>
                        Elite
                      </TextRank>
                    </div>
                  </CardRank>
                </div>
              </div>
            </div>
            <div className="pt-5">
              <h4 className="mt-4 text-white font-bold-iux text-center">
                {locale.t("loyalty_status.header")}
              </h4>
              <div className="pb-4">
                <p className="text-of-white text-center">
                  {locale.t("loyalty_status.title")}
                </p>
              </div>
              <div className="row mt-4 justify-center">
                <div className="col-md-12 col-lg-3 mb-4 mb-lg-0 text-of-white text-center">
                  <TextRank fontSize={24}>Rising star</TextRank>
                  <div>Beginner</div>
                  <div>0.3 IRP/LOT</div>
                </div>
                <div className="d-none d-md-none d-lg-flex">
                  <Border />
                </div>
                <div className="col-md-12 col-lg-3 mb-4 mb-lg-0 text-of-champ text-center">
                  <TextRank color="#DCBE59" fontSize={24}>
                    Champion
                  </TextRank>
                  <div>Beginner</div>
                  <div>0.5 IRP/LOT</div>
                </div>
                <div className="d-none d-md-none d-lg-flex">
                  <Border />
                </div>
                <div className="col-md-12 col-lg-3 mb-4 mb-lg-0 text-of-elite text-center">
                  <TextRank color="#D75554" fontSize={24}>
                    Elite
                  </TextRank>
                  <div>Beginner</div>
                  <div>0.8 IRP/LOT</div>
                </div>
              </div>
            </div>
            {/* sec 3 */}
            <div className="pt-5">
              <h4 className="mt-4 text-white font-bold-iux text-center">
                {locale.t("rewards.header")}
              </h4>
              <div className="pb-4">
                <p className="text-of-white text-center">
                  {locale.t("rewards.title")}
                </p>
              </div>
              <div className="mt-4 row justify-center">
                <Box className="row justify-content-between align-items-center p-4">
                  <div className="text-white col-7 col-md-9">
                    <div className="font-weight-bold">Bonus = IRP</div>
                    <div
                      style={{
                        height: "1px",
                        background: "black",
                        margin: "0.5rem 0",
                      }}
                    />
                    <div className="font-weight-bold">Balance = IRP/2</div>
                  </div>
                  <div className="text-color-iux text-center col-4 col-md-3">
                    <TextIUX fontSize="30px">IRP</TextIUX>
                    <TextIUX fontSize="20px">Bonus</TextIUX>
                  </div>
                </Box>
              </div>
            </div>
          </div>
          <div className="container">
            <div style={{ borderTop: "2px solid #80808029" }} />
          </div>
        </div>
      </section>
      <section key={3}>
        <div className="bg-black">
          <div className="container pt-4 pb-5 pr-2 pl-2 pl-sm-5 pr-sm-5">
            <div className="row">
              {[
                "Open account",
                "Deposit on account",
                "Trading",
                "Get IRP",
                "Redeem points",
              ].map((e, i) => (
                <>
                  <div className="text-white text-center col">
                    <div className="text-primary-new-iux fs-56-lot ">
                      {i + 1}
                    </div>
                    <div className="fs-12">{e}</div>
                  </div>
                  {i !== 4 && (
                    <div className="d-none d-md-none d-lg-flex">
                      <Border />
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="text-white text-center mt-40">
              All profits generated can be withdrawn at any time. However, when
              a withdrawal occurs Your trading bonus will be deducted from your
              account in equal proportions.
            </div>
            <div className="text-white text-center mt-3">
              Read the full terms and conditions{" "}
              <Link
                to={`/${localStorage.getItem("locale")}/about-us/condition`}
              >
                <span className="text-primary-new-iux">here</span>
              </Link>{" "}
              .
            </div>
          </div>
          <div className="container">
            <div style={{ borderTop: "2px solid #80808029" }} />
          </div>
        </div>
      </section>
      <section key={10}>
        <FooterLandingPage locale={locale} />
      </section>
    </RcQueueAnim>
  );
}

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(mapStateToProps, null)(LotbackBonus);
