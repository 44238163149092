import { Button, Divider } from "antd";
import React from "react";
import TextWithCover from "../../components/text-with-cover/TextWithCover";
import RcQueueAnim from "rc-queue-anim";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FooterLandingPage from "../../components/footer-landing-page/FooterDark";

import { Helmet } from "react-helmet";
// import TextWithCoverCenter from "../../components/text-with-cover/TextWithCoverCenter";
// import SectionMt5Dark from "../../components/landingpages/section-mt5-dark";
// import TextWithCoverMobile from "../../components/text-with-cover/TextWithCoverMobile";
import Slidebonus from "./component/slidebonus";
const TITLE = "Promotion | IUX";
const locale = require("react-redux-i18n").I18n;

function StartBonus(props) {
  return (
    <RcQueueAnim className="bg-black">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <section key={1} id="bonus" style={{ width: "100%" }}>
        {/* <div className="d-none d-md-block"> */}
        <TextWithCover
          key={3}
          styles={{ backgroundPosition: "center !important" }}
          imagePath={
            process.env.PUBLIC_URL +
            "/assets/images/covers/cover-promotions.png"
          }
          title={locale.t("bonus_header")}
          content={
            <div>
              {locale.t("bonus_content")}
              <Link to={`/${localStorage.getItem("locale")}/register`}>
                <div className="d-flex justify-content-center justify-content-sm-start">
                  <div className="">
                    <Button className="mt-4 col-md-12 mt-2  btn-primary-new-iux">
                      {locale.t("bonus_button")}
                    </Button>
                  </div>
                </div>
              </Link>
            </div>
          }
        />
        {/* </div> */}
        {/* <div className="d-block d-md-none">
          <TextWithCoverMobile
            key={3}
            styles={{ backgroundPosition: "center !important" }}
            imagePath={"/assets/images/promotions/promotion-banner.png"}
            title={locale.t("bonus_header")}
            content={
              <div>
                {locale.t("bonus_content")}
                <Link to={`/${localStorage.getItem("locale")}/register`}>
                  <div className="d-flex justify-content-center justify-content-sm-center">
                    <div className="">
                      <Button className="mt-4 col-md-12 mt-2  btn-iux-primary-fill">
                        {locale.t("bonus_button")}
                      </Button>
                    </div>
                  </div>
                </Link>
              </div>
            }
          />
        </div> */}
      </section>
      <div className="d-block d-md-none">
        <Slidebonus />
      </div>
      <section key={2} className=" d-none d-md-block   py-5">
        <div
          className="container"
          style={{
            background: "url('/assets/images/promotions/bonus-30.png')",
            backgroundSize: "100% 100%",
            padding: "4rem 2rem",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="row justify-content-between align-items-center mt-4">
            <div className="col-12 col-sm-8 col-md-8 col-lg-6">
              {" "}
              <h4 className=" text-white fs-28 text-uppercase fw-light">
                {locale.t("promo_contain1.title")}
              </h4>
              <div className="">
                {/* <h6
                  className="mt-2 text-white fw-light fs-16 "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  No deposit required
                </h6> */}
                <h6
                  className="mt-2 text-white fw-light "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  {locale.t("promo_contain1.section1")}
                </h6>
                <h6
                  className="mt-2 text-white fw-light "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  {locale.t("promo_contain1.section2")}
                </h6>
                <h6
                  className="mt-2 text-white fw-light "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  {locale.t("promo_contain1.section3")}
                </h6>
                <h6
                  className="mt-2 text-white fw-light "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  {locale.t("promo_contain1.section4")}
                </h6>
                <h6
                  className="mt-2 text-white fw-light "
                  style={{
                    lineHeight: "2",
                    textAlign: "left",
                  }}
                >
                  <img
                    src="/assets/images/icons/tick.svg"
                    alt="true"
                    className="me-3"
                  />
                  {locale.t("promo_contain1.section5")}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section key={3} className="d-none d-md-block">
        <div className="container ">
          <div className=" mt-4 row" style={{ marginBottom: "5rem" }}>
            <div className="col-12 col-md-6 h-100">
              <div
                style={{
                  background: "url('/assets/images/promotions/bonus-35.png')",
                  height: 320,
                  backgroundSize: "100% 100%",
                  padding: "4rem 2rem",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="col-6">
                  <div className="f-lg-30 f-md-24 text-white ">
                    {locale.t("promo_contain2")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 h-100">
              <div
                style={{
                  background: "url('/assets/images/promotions/bonus-25.png')",
                  backgroundSize: "100% 100%",
                  height: 320,
                  padding: "4rem 2rem",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="col-6">
                  <div className="f-lg-30 f-md-24 text-white ">
                    {locale.t("promo_contain3")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section key={4} className="container mt-5">
        <div
          className="pb-5"
          style={{
            background: "url('/assets/images/landing/img-section4.png')",
            backgroundSize: "100% 100%",
          }}
        >
          <h4 className="text-center text-white fs-32 text-uppercase fw-light pt-5 fs-xs-24">
            {locale.t("promo_contain4.title")}
          </h4>
          <div className="row">
            <div className="d-flex mt-3 mt-md-5   col-12 col-md-4">
              <div className="ms-4 text-white fs-16 text-start">
                <p className="text-primary-new-iux fw-light text-start">
                  {locale.t("promo_contain4.section1.title")}
                </p>
                <p className="text-white text-start">
                  {locale.t("promo_contain4.section1.des")}
                </p>{" "}
              </div>
            </div>
            <div className="d-flex mt-3 mt-md-5 col-12 col-md-4">
              <div className="ms-4 text-white fs-16 text-start">
                <p className="text-primary-new-iux fs-20fw-light text-start">
                  {locale.t("promo_contain4.section2.title")}
                </p>
                <p className="text-white text-start " style={{ maxWidth: 480 }}>
                  {locale.t("promo_contain4.section2.des")}
                </p>{" "}
              </div>
            </div>
            <div className="d-flex mt-3 mt-md-5  col-12 col-md-4">
              <div className="ms-4 text-white fs-16 text-start">
                <p className="text-primary-new-iux fs-20fw-light text-start">
                  {locale.t("promo_contain4.section3.title")}
                </p>
                <p className="text-white text-start">
                  {locale.t("promo_contain4.section3.des")}
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <SectionMt5Dark /> */}
      <section key={10}>
        <FooterLandingPage locale={locale} />
      </section>
    </RcQueueAnim>
  );
}

const mapStateToProps = ({ i18n }) => ({
  locale: i18n.locale,
});

export default connect(mapStateToProps, null)(StartBonus);
